import { geoMercator } from "d3-geo";
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  // Marker,
  ZoomableGroup,
} from "react-simple-maps";
const geoUrl = //'https://simplemaps.com/static/data/country-cities/dk/dk.json'
  //'https://raw.githubusercontent.com/deldersveld/topojson/master/countries/denmark/denmark-municipalities.json'
  "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/denmark/denmark-counties.json";

const filterColor = (text) => {
  console.log(text);
  const coordsArray = text.geometry.coordinates.flat(Infinity);
  const coordsArray1 = text.properties.NAME_1 === 'Sjælland'?.geometry
  // ?.coordinates[0]
  // ?.map((coordinate) => projection({ coordinates: coordinate }))
  // ?.join('L');
  const filteredCoords = coordsArray.filter((a) => a);
  const match = filteredCoords.filter(coo => coo.toFixed(2) === 55.46 || coo.toFixed(2) === 11.72)
 const a = text.rsmKey.includes("10") && text.geometry.coordinates;//.map(a =>a.flat(Infinity));
  console.log(/* match, */ coordsArray1)
  
  if (
    // text.svgPath.includes('-625.3784323897432L529.7277734608795')
    // ||
    // text.svgPath.includes('-632.6478803006743Z') ||
    text.svgPath.includes('M525.3470187312779') ||
    text.svgPath.includes('M535.4789035354289') ||
    text.svgPath.includes('M529.5673767985979') ||
    text.svgPath.includes('M532.782') ||
    text.svgPath.includes('M532.7817093899174') ||
    text.svgPath.includes('M541.59090730543') 
    // text.rsmKey.includes("12") ||
    // text.rsmKey.includes("8") ||
    // text.rsmKey.includes("10") ||
    // text.rsmKey.includes("2")
    
  ) {
    // return match.fill("#b5b5de")
    return  "red"
  }
  else return 'grey'
};

const projection = geoMercator().scale(4000).translate([400, 650]);

const findGeo = (geo) =>{
 return geo && geo.features &&
  geo.features.find((feature) => feature.properties.NAME_1 === 'Sjælland')?.geometry
    ?.coordinates[0]
    ?.map((coordinate) => projection({ coordinates: coordinate }))
    ?.join('L');
}

const findCoordinates = (array) => {
  console.log(array.map((text) => text));
};
const MapChart = () => {
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      viewBox="480 -680 100 100"
      projectionConfig={{
        scale: [1000],
      }}
    >
      <ZoomableGroup center={[0, 0]} zoom={1}>
        <Geographies geography={geoUrl}>
          {({ geographies }) => {
            const sjaelland = { coordinates: ["11.721498", "55.463252"] };
            return geographies.map((geo) => (
              // console.log(geo.svgPath, geo.geometry.coordinates),
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={filterColor(geo)}
                // fill={findGeo(geo) ? 'blue':'red'}
                // fill={findCoordinates(geo.geometry.coordinates)}
                // fill={sjaelland ? "red" : "blue"}
                stroke="#D6D6DA"
                strokeWidth={0.1}
              />
            ));
          }}
        </Geographies>
        {/* <Marker coordinates={["11.721498", "55.463252"]}>
          <circle r={1} fill="blue" stroke="#fff" strokeWidth={0.1} />
        </Marker> */}
      </ZoomableGroup>
    </ComposableMap>
  );
};

export default MapChart;
