import React, { useRef, useEffect } from "react";

export default function TrustBox() {
  const ref = useRef(null);
  const dataTemplateId = process.env.REACT_APP_DATA_TEMPLATE_ID;
  const dataBusinessId = process.env.REACT_APP_DATA_BUSINESS_ID;

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <>
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="da-DK"
        data-template-id={dataTemplateId}
        data-businessunit-id={dataBusinessId}
        data-style-height="24px"
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-style-alignment="center"
      >
        <a
          href="https://dk.trustpilot.com/review/profexgroup.dk"
          target="_blank"
          rel="noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
}
