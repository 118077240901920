import React /* { useRef } */ from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import "../InfoCard/InfoCard.css";
// import TrackViewPort from "../ViewPortChecker/TrackViewport";

export default function InfoCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);
  // const viewPortRef = useRef();
  // const inViewport = TrackViewPort(viewPortRef, "0px");

  const handleRedirect = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      className="text-something"
      sx={{
        marginTop: "2em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      // ref={viewPortRef}
    >
      <CardMedia
        component="img"
        height="194"
        image={
          data?.img ||
          `https://gumiimpex.hr/upload/rotator_element/webpnet-resizeimage-7-5dd01011ad3b4_5de59991b1cc9.jpg`
        }
        alt={data?.title + " " + "billede"}
      />
      <CardContent
        // className={inViewport ? "visible" : "not-visible"}
        sx={{
          // top: "100%",
          position: "relative",
          transition: "all 3s ease-out",
          // height: "100%",
        }}
      >
        <Typography variant="h4">{data?.title}</Typography>
        <Typography variant="body2" color="text.secondary">
          {data?.description}
        </Typography>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {data?.children?.map((child) => (
            <Typography key={child}>- {child}</Typography>
          ))}
        </div>
      </CardContent>
      <CardActions
        disableSpacing
        style={{ justifyContent: "center" }}
      ></CardActions>
    </Card>
  );
}
